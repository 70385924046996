/* eslint-disable-next-line */
__webpack_public_path__ = '/static/js/secondaryarea/';
import Slider from '../common/slider';
class SecondaryArea extends lm.Composant {
  /**
   * @constructor
   * @param {String} id
   */
  constructor(id) {
    super(id);
    [...document.querySelectorAll('.o-secondary .js-slider')].forEach(element => {
      new Slider({
        element,
        manualScroll: 'touch'
      });
    });
  }
}
lm.DOMReady(() => {
  new SecondaryArea('component-secondaryarea');
});